import React from "react"
import { graphql, PageProps, Link } from "gatsby"
import { Button } from "../components/ui"
import Layout from "../components/layout"
import { ContactQuery } from "./__generated__/ContactQuery"
import Img from "gatsby-image"
import { gestureProps } from "framer-motion/types/motion/features/gestures"
import Popup from "../components/Popup"

export default ({ data, location }) => {
    return (
        <Layout
            seo={{
                title: "アクセス",
                description:
                    "当販売店の店舗情報を明記しております。お気軽にご連絡ください。",
            }}
            location={location}
        >
            <section className="text-gray-600 body-font relative">
                <div className="title py-12 text-center">
                    <h2 className="font-black text-5xl text-color-4">
                        アクセス
                    </h2>
                </div>
                <div className="container px-5 py-6 mx-auto flex sm:flex-nowrap flex-wrap ">
                    <div className="w-full h-64 sm:min-h-screen bg-gray-300 rounded-lg sm:mr-10 p-10 flex items-end justify-start relative">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.5749090480426!2d139.69166281513137!3d35.66284358019858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188cac5e52b721%3A0x86bc54b5436c5255!2z44OL44Ol44O844K544K144O844OT44K55pel57WMIOa4i-iwtw!5e0!3m2!1sja!2sjp!4v1656495913803!5m2!1sja!2sjp"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            style={{ border: 0 }}
                            allowFullScreen={false}
                            aria-hidden="false"
                            tabIndex={0}
                            marginHeight={0}
                            marginWidth={0}
                            scrolling="no"
                            className="absolute inset-0"
                        ></iframe>
                        {/* <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                            <div className="lg:w-1/2 px-6">
                                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                                    ADDRESS
                                </h2>
                                <p className="mt-1">
                                    Photo booth tattooed prism, portland taiyaki
                                    hoodie neutra typewriter
                                </p>
                            </div>
                            <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                                    EMAIL
                                </h2>
                                <a className="text-indigo-500 leading-relaxed">
                                    example@email.com
                                </a>
                                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                                    PHONE
                                </h2>
                                <p className="leading-relaxed">123-456-7890</p>
                            </div>
                        </div> */}
                    </div>
                    <div className="bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
                        <h2 className="text-gray-900 text-2xl mb-1 font-medium title-font">
                            店舗情報
                        </h2>
                        <br />
                        <p className="leading-relaxed mb-5 text-gray-600">
                            所長：{data.site.siteMetadata.contact.name}
                        </p>
                        <p className="leading-relaxed mb-5 text-gray-600">
                            住所：{data.site.siteMetadata.contact.address}
                        </p>
                        <p className="leading-relaxed mb-5 text-gray-600">
                            電話：
                            <a className="" href={"tel:" + data.phone}>
                                {data.site.siteMetadata.contact.phone}
                            </a>
                            <br className="" />
                            フリーダイヤル{" "}
                            <a className="" href={"tel:" + data.free}>
                                {data.site.siteMetadata.contact.free}
                            </a>
                        </p>
                        <p className="leading-relaxed mb-5 text-gray-600">
                            Fax：{data.site.siteMetadata.contact.fax}
                        </p>
                        <p className="leading-relaxed mb-5 text-gray-600">
                            メール：
                            <a className="" href={"mailto:" + data.mail}>
                                {data.site.siteMetadata.contact.mail}
                            </a>
                        </p>
                        {/* <div>
                            <a href="../static/images/shinagawa.jpeg">
                                <Button
                                    type="button,submit"
                                    title="印刷する"
                                    // disabled={promiseInProgress}
                                    // iconRight={<IconRight spin={promiseInProgress} />}
                                    aria-label="印刷する"
                                />
                            </a>
                        </div> */}
                    </div>
                </div>
            </section>
            {/* <div className="container mx-auto h-full py-12 px-12 content-center">
                <div className="title py-12 text-center">
                    <h2 className="font-black text-5xl text-color-4">
                        アクセス
                    </h2>
                </div>
                <Img fluid={data.file.childImageSharp.fluid} />
            </div> */}
            <Popup />
        </Layout>
    )
}

export const query = graphql`
    query {
        site {
            siteMetadata {
                contact {
                    api_url
                    description
                    name
                    mail
                    phone
                    free
                    fax
                    address
                }
            }
        }
    }
`
